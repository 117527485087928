import React from 'react';
import { SITE_METADATA } from 'src/constants';
import Layout from 'src/components/Layout/Layout';
import Hidden from '@material-ui/core/Hidden';
import Plus6PayWhatPaidyCanDo from 'src/components/Landing/Plus6Pay/Plus6PayWhatPaidyCanDo';
import Plus12PayTopBanner from 'src/components/Landing/Plus12Pay/Plus12PayTopBanner';
import Footer6Pay from 'src/components/Landing/Footer6Pay';
import VerifyIdentity from 'src/components/Landing/VerifyIdentity';
import UseInstallments12Pay from 'src/components/Landing/UseInstallments12Pay';
import { Box } from '@material-ui/core';

const SEOProps = {
  title: SITE_METADATA.plus_12Pay.title,
  description: SITE_METADATA.plus_12Pay.description,
};

const TITLE_IDENTITY = (
  <>
    無料アップグレードは、
    <Hidden smUp>
      <br />
    </Hidden>
    たったの５分で完了。
  </>
);

export default function Plus12Pay() {
  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter>
      <Plus12PayTopBanner />
      <Box style={{ backgroundColor: '#ffffff' }}>
        <UseInstallments12Pay title="使い方はとってもかんたん！" />
      </Box>
      <VerifyIdentity title={TITLE_IDENTITY} />
      <Plus6PayWhatPaidyCanDo title="ペイディが選ばれる理由" />
      <Footer6Pay />
    </Layout>
  );
}

import React from 'react';
import QrAndBadgesSwitch from 'src/components/QrAndBadgesSwitch';
import CartImg from 'src/images/6pay/cart.png';
import PayStep3Img from 'src/images/6pay/pay-step-3.png';
import PayStep4Img from 'src/images/6pay/pay-step-4-12pay.png';
import UseInstallments from './UseInstallments';

const ITEMS = [
  {
    ImgComponent: () => <QrAndBadgesSwitch />,
    figCaption: 'ペイディアプリから本人確認する。',
  },
  {
    ImgComponent: () => (
      <img alt="cart" src={CartImg} width={184} height={184} />
    ),
    figCaption: 'ショップで欲しい商品をカートに入れる。',
  },
  {
    ImgComponent: () => (
      <img alt="pay-step-3" src={PayStep3Img} width={184} height={184} />
    ),
    figCaption:
      'お支払い方法で「あと払い（ペイディ）」を選択して、メールアドレスと携帯電話番号を入力する。',
  },
  {
    ImgComponent: () => (
      <img alt="pay-step-4" src={PayStep4Img} width={184} height={184} />
    ),
    figCaption:
      'ご希望のお支払い回数を選んで完了。一括払いで購入後に、ペイディアプリから変更することも可能です。',
  },
];

export default function UseInstallments12Pay({
  title = '3・6・12回あと払いのご利用方法',
  data = ITEMS,
  noArrows,
}) {
  return <UseInstallments title={title} data={data} noArrows={noArrows} />;
}

import React from 'react';
import useAppURLsByDevice from 'src/hooks/useAppUrlsByDevice';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Hidden from '@material-ui/core/Hidden';
import QrImg from 'src/images/6pay/qr-code-full.png';
import ShopImg from 'src/images/landing/plus6pay/shop.png';
import ShopSpImg from 'src/images/landing/plus6pay/shop-sp.png';
import PaidyLogoImg from 'src/images/logo-paidy-color-big.svg';
import AppStoreImg from 'src/images/badge-app-store.png';
import GooglePlayImg from 'src/images/badge-play-store.png';
import Step1Img from 'src/images/landing/plus6pay/step-1.png';
import Step2Img from 'src/images/landing/plus12pay/step-2.png';
import Step3Img from 'src/images/landing/plus6pay/step-3.png';
import styles from './Plus12PayTopBanner.module.scss';
import { trackAppDownload } from '../../../utils';

const STEPS = [
  {
    key: 'step-1',
    title: (
      <>
        例えば、
        <br />
        60,000円の商品なら…
      </>
    ),
    img: Step1Img,
  },
  {
    key: 'step-2',
    title: (
      <>
        5,000円ずつ、
        <br />
        12か月でお支払い！
      </>
    ),
    img: Step2Img,
  },
  {
    key: 'step-3',
    title: 'かしこく計画的にGET。',
    img: Step3Img,
  },
];

const sp = {
  appStoreUrl: 'https://d9r4z.app.goo.gl/6pay',
  playStoreUrl: 'https://d9r4z.app.goo.gl/6pay',
};

const pc = {
  appStoreUrl: 'https://apps.apple.com/JP/app/id1220373112?mt=8',
  playStoreUrl:
    'https://play.google.com/store/apps/details?id=com.paidy.paidy&referrer=af_tranid%3DJK6y7wDsEZLNEvXx9Zt3pQ%26shortlink%3DPC%26c%3DPC%26pid%3Dwebsite%26af_channel%3Dbutton%26source_caller%3Dui%26af_web_id%3D881895a9-99cf-4d63-8407-23ccc2fa2d1a-o',
};

export default function Plus6PayTopBanner() {
  const { appStoreUrl, playStoreUrl } = useAppURLsByDevice(sp, pc);

  return (
    <section className={styles.section}>
      <div className={styles.rowTop}>
        <div className={styles.left}>
          <img
            alt="paidy"
            src={PaidyLogoImg}
            width={272}
            height={78}
            className={styles.logo}
          />
          <p className={styles.head1}>
            高額商品を
            <br />
            もっとかしこく購入したい…
          </p>
          <p className={styles.head2}>＼そんな時は！／</p>
          <h1 className={styles.head3}>
            ペイディの3・6・12回
            <br />
            あと払い分割手数料無料*
          </h1>
          <Hidden xsDown>
            <p className={styles.head4}>
              *口座振替・銀行振込のみ無料
              <br />
              <br />
              ※3回あと払いは1回のお買い物につき、3,000円以上、6回あと払いは1回のお買い物につき6,000円以上、12回あと払いは1回のお買い物につき12,000円以のお支払いで利用可能です。
            </p>
          </Hidden>
        </div>
        <Hidden xsDown>
          <img
            alt="shop"
            width={717}
            height={627}
            src={ShopImg}
            className={styles.imgShop}
          />
        </Hidden>
        <Hidden smUp>
          <img alt="shop" src={ShopSpImg} className={styles.imgShop} />
        </Hidden>
        <Hidden smUp>
          <p className={styles.head4}>
            *口座振替・銀行振込のみ無料
            <br />
            ３回あと払いは1回のお買い物につき、3,000円以上のお支払いで利用可能です。
            <Hidden xsDown>
              <br />
            </Hidden>
            6回あと払いは1回のお買い物につき6,000円以上のお支払いで利用可能です。
          </p>
        </Hidden>
      </div>
      {/* Steps */}
      <div className={styles.steps}>
        {STEPS.map((step) => (
          <div>
            <p className={styles.title}>{step.title}</p>
            <img
              src={step.img}
              alt={step.key}
              width={300}
              height={320}
              className={styles.img}
            />
          </div>
        ))}
      </div>
      {/* QR and badges */}
      <div className={styles.bottom}>
        <Hidden xsDown>
          <OutboundLink
            href="https://d9r4z.app.goo.gl/6pay"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img alt="qr" src={QrImg} width={130} height={130} />
          </OutboundLink>
        </Hidden>
        <div>
          <p className={styles.text}>
            ペイディアプリをダウンロードして
            <br />
            今すぐ3・6・12回あと払いを使ってみる
          </p>
          <div className={styles.badgesContainer}>
            <a
              href={appStoreUrl}
              rel="noopener noreferrer"
              target="_blank"
              onClick={() =>
                trackAppDownload('App Store', 'plus_12pay_top_banner')
              }
            >
              <img
                src={AppStoreImg}
                alt="Apple App Store - Paidy"
                loading="lazy"
                width={140}
                height={42}
              />
            </a>
            <a
              href={playStoreUrl}
              rel="noopener noreferrer"
              target="_blank"
              onClick={() =>
                trackAppDownload('Google Play', 'plus_12pay_top_banner')
              }
            >
              <img
                src={GooglePlayImg}
                alt="Google Play Store - Paidy"
                loading="lazy"
                width={140}
                height={42}
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
